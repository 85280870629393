import i18next from 'i18next';

i18next.addResources('es_AR', 'LoginForm', {
  signIn: 'INICIAR SESIÓN',
  email: 'USUARIO',
  password: 'CONTRASEÑA'
});

i18next.addResources('es_AR', 'Errors', {
  required: 'Este campo es requerido'
});

i18next.addResources('es_AR', 'Names', {
  email: 'email',
  password: 'password'
});
