import i18next from 'i18next';

i18next.addResources('es_AR', 'DeliverTypeColumn', {
  deliver: 'ENTREGA',
  delivery: 'Delivery',
  takeaway: 'Retiro',
  takeawayAddress: 'Uriarte 1271, CABA',
  takeawayHour: '9 a 14:30hs',
  takeawayHourSaturday: '8 a 12:00hs'
});
