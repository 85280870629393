import Immutable from 'seamless-immutable';
import { createReducer, completeState } from 'redux-recompose';
import onReadValue from 'redux-recompose/lib/effects/onReadValue';

import { actions } from './actions';

const stateDescription = {
  cart: []
};

const initialState = completeState(stateDescription, ['cart']);

const reducerDescription = {
  [actions.SUM_ITEM]: onReadValue(),
  [actions.REMOVE_ITEM]: onReadValue(),
  [actions.CLEAN_CART]: onReadValue()
};

export default createReducer(new Immutable(initialState), reducerDescription);
