import { create } from 'apisauce';
import { CamelcaseSerializer, SnakecaseSerializer } from 'cerealizr';

import AuthActions from '~redux/auth/actions'
import LocalStorageService from '~services/LocalStorageService'

const camelSerializer = new CamelcaseSerializer();
const snakeSerializer = new SnakecaseSerializer();

export const api = create({
  baseURL: process.env.REACT_APP_BASE_URL
});

export const headers = {
  AUTHORIZATION: 'Authorization'
};

export const setAuthHeader = token => api.setHeader(headers.AUTHORIZATION, `Bearer ${token}`);

export const removeAuthHeader = () => api.deleteHeader(headers.AUTHORIZATION);

export const apiSetup = dispatch => {
  api.addMonitor(response => {
    if (response.status === 401) {
      const token = LocalStorageService.getSessionToken();
      if (token) {
        dispatch(AuthActions.logout());
      }
    }
  });

  api.addResponseTransform(response => {
    if (response.data) {
      response.data = camelSerializer.serialize(response.data);
    }
  });

  api.addRequestTransform(request => {
    if (request.data) {
      // request.data = snakeSerializer.serialize(request.data);
    }
  });
}

export default api;
