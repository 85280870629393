export const createDeliveringDate = (deliveringDayOfWeek, deliveryType) => {
  const todaysDate = new Date();
  const distance = (deliveringDayOfWeek + 7 - todaysDate.getDay()) % 7;
  todaysDate.setDate(
    distance ? todaysDate.getDate() + distance : todaysDate.getDate() + (deliveryType === 'takeaway' ? 0 : 7)
  );
  // not the best solution but in a hurry. sorry.
  return todaysDate;
};

export const compareDates = (aDate, anotherDate) => aDate.toDateString() === anotherDate.toDateString();

export const buildDateForModal = date => `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;

export const TRANSLATE_DATE_TO_ES = {
  monday: 'Lunes',
  tuesday: 'Martes',
  wednesday: 'Miercoles',
  thursday: 'Jueves',
  friday: 'Viernes',
  saturday: 'Sabado'
};

export const WORKING_DAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export const UTCDATE_PER_DATE = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6
};

export const DATE_PER_UTC = {
  0: 'sunday',
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday'
};

export const today = () => new Date().getDay();

export const dateString = date => date.slice(0, 10);

export const getNextDate = date => {
  const tomorrow = new Date(date);
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow;
};

export const convertDateToUTC = date =>
  new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );

export const toIsoWithTimezone = date => {
  const tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? '+' : '-',
    pad = function (num) {
      const norm = Math.floor(Math.abs(num));
      return (norm < 10 ? '0' : '') + norm;
    };
  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
};

export const isDay = (date, day) => date.getDay() === day;

export const SATURDAY = 6;

export const itsPast = number => new Date().getHours() >= number;
