import React, { useEffect } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Suspense from '~components/Suspense';
import { history } from '~redux/store';
import AuthActions from '~redux/auth/actions';
import { CLIENT_PAGES, BACKOFFICE_PAGES } from '~constants/pages';

import { Login, Home, Orders } from './constants';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import styles from './styles.module.scss';

function AppRoutesContainer() {
  const dispatch = useDispatch();
  useEffect(() => dispatch(AuthActions.setUp()), [dispatch]);
  return (
    <ConnectedRouter history={history}>
      <div className={`column center ${styles.container}`}>
        <Suspense>
          <>
            <Switch>
              <AuthenticatedRoute {...BACKOFFICE_PAGES.LOGIN} component={Login} />
              <AuthenticatedRoute {...BACKOFFICE_PAGES.ORDERS} component={Orders} />
              <AuthenticatedRoute {...CLIENT_PAGES.HOME} component={Home} />
            </Switch>
          </>
        </Suspense>
      </div>
    </ConnectedRouter>
  );
}

export default AppRoutesContainer;
