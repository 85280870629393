import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { string } from 'prop-types';
import { Helmet } from 'react-helmet';
import { Route, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';

import { BACKOFFICE_PAGES } from '~constants/pages';
import LocalStorage from '~services/LocalStorageService';

import styles from './styles.module.scss';

function AuthenticatedRoute({ title, description, isPublicRoute, isBackofficeRoute, path, ...props }) {
  const token = LocalStorage.getSessionToken();
  return !token && !isPublicRoute ? (
    <Redirect
      to={{
        pathname: BACKOFFICE_PAGES.LOGIN.path
      }}
    />
  ) : token && isPublicRoute && isBackofficeRoute ? (
    <Redirect
      to={{
        pathname: BACKOFFICE_PAGES.ORDERS.path
      }}
    />
  ) : (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <div className={cn(styles.containerSite, 'item-1')}>
        <div className={`column full-width center ${styles.mainContent}`}>
          <Route path={path} {...props} />
        </div>
      </div>
    </>
  );
}

AuthenticatedRoute.propTypes = {
  path: string.isRequired,
  description: string,
  title: string
};

export default withRouter(AuthenticatedRoute);
