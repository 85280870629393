import { api } from '~config/api';
import { downloadFile } from '~config/download';
import { getNextDate, dateString } from '~utils/date';

const createOrder = order => api.post('/orders', order);

const getOrders = () => api.get('/orders');

const updateOrder = ({ order, status }) => api.put(`/orders/${order.id}`, status);

const downloadOrders = fromDate => () =>
  api
    .get(
      `/orders/csv?orders[filter][deliver.date][gte]=${dateString(
        fromDate.toISOString()
      )}&orders[filter][deliver.date][lt]=${dateString(getNextDate(fromDate).toISOString())}`
    )
    .then(res => {
      if (res.status === 200) {
        downloadFile(res.data, `pedidos_${fromDate}.xls`);
      }
      return res;
    });

export default { createOrder, getOrders, updateOrder, downloadOrders };
