import immutable from 'seamless-immutable';
import { createReducer, completeReducer, completeState } from 'redux-recompose';

import { actions } from './actions';

const defaultState = completeState({
  products: []
});

const reducerDescription = {
  primaryActions: [actions.GET_PRODUCTS]
};

const reducer = createReducer(immutable(defaultState), completeReducer(reducerDescription));
export default reducer;
