import i18next from 'i18next';

export const CLIENT_PAGES = {
  HOME: {
    isPublicRoute: true,
    path: '/'
  },
  UNAVAILABLE: {
    exact: true,
    path: i18next.t('Pages:notAvailable')
  }
};

export const BACKOFFICE_PAGES = {
  ORDERS: {
    isBackofficeRoute: true,
    path: '/admin'
  },
  LOGIN: {
    isPublicRoute: true,
    isBackofficeRoute: true,
    path: '/admin/login'
  },
  UNAVAILABLE: {
    exact: true,
    path: i18next.t('Pages:notAvailable')
  }
};
