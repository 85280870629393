import { createTypes, completeTypes, withPostSuccess } from 'redux-recompose';
import { push, replace } from 'connected-react-router';
import withPostFailure from 'redux-recompose/lib/injections/withPostFailure';

// import Routes from '~constants/routes';
import { CLIENT_PAGES, BACKOFFICE_PAGES } from '~constants/pages';
import AuthService from '~services/auth/authService';
import { setAuthHeader, removeAuthHeader, apiSetup } from '~config/api';
import LocalStorageService from '~services/LocalStorageService';

import { history } from '../store';

export const actions = createTypes(
  completeTypes(
    ['LOGIN', 'GET_CURRENT_USER', 'GET_USERS', 'GET_USER_BY_ID'],
    ['INIT', 'SET_BACKOFFICE', 'LOGOUT', 'SET_SUCCESS_MESSAGE']
  ),
  '@@AUTH'
);

const targets = {
  INITIAL_LOADING: 'initialLoading',
  LOGIN: 'login',
  BACKOFFICE: 'isBackoffice',
  CURRENT_USER: 'currentUser',
  SUCCESS_MESSAGE: 'successMessage',
  USERS: 'users',
  USER: 'user'
};

const actionCreators = {
  setUp: () => (dispatch, getState) => {
    apiSetup(dispatch, getState);
    const token = LocalStorageService.getSessionToken();
    if (token) {
      setAuthHeader(token);
      dispatch(actionCreators.init());
    }
  },
  init: () => ({
    type: actions.INIT,
    target: targets.INITIAL_LOADING
  }),
  login: values => ({
    type: actions.LOGIN,
    service: AuthService.login,
    payload: values,
    target: targets.LOGIN,
    failureSelector: response => response.data,
    injections: [
      withPostSuccess((dispatch, response) => {
        if (response.data) {
          const { token } = response.data;
          setAuthHeader(token);
          LocalStorageService.setSessionToken(token);
          dispatch(push(BACKOFFICE_PAGES.ORDERS.path));
        } else {
          dispatch(actionCreators.showSuccessMessage(true));
        }
      })
    ]
  }),
  showSuccessMessage: show => ({
    type: actions.SET_SUCCESS_MESSAGE,
    target: targets.SUCCESS_MESSAGE,
    payload: show
  }),
  setBackoffice: backoffice => ({
    type: actions.SET_BACKOFFICE,
    target: targets.BACKOFFICE,
    payload: backoffice
  }),
  logout: () => dispatch => {
    dispatch({
      type: actions.LOGOUT,
      target: targets.CURRENT_USER
    });
    removeAuthHeader();
    LocalStorageService.removeSessionToken();
    dispatch(push('/admin/login'));
  },
  getCurrentUser: () => ({
    type: actions.GET_CURRENT_USER,
    target: targets.CURRENT_USER,
    service: AuthService.getCurrentUser,
    successSelector: response => response.data.user,
    injections: [
      withPostSuccess((dispatch, response) => {
        const { role } = response.data.user;
        const isBackoffice = role === 'admin';
        dispatch(actionCreators.setBackoffice(isBackoffice));
        dispatch(actionCreators.init());
      }),
      withPostFailure(dispatch => {
        // dispatch(push(Routes.LOGIN));
        dispatch(actionCreators.init());
      })
    ]
  }),
  getUsers: () => ({
    type: actions.GET_USERS,
    service: AuthService.getUsers,
    target: targets.USERS
  }),
  getUserById: userId => ({
    type: actions.GET_USER_BY_ID,
    service: AuthService.getUserById,
    payload: userId,
    target: targets.USER
  })
};

export default actionCreators;
