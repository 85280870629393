import { CLIENT_PAGES, BACKOFFICE_PAGES } from '~constants/pages';
import dynamicImportComponent from '~utils/dynamicImports';

const LoginImport = () => import('~screens/Backoffice/screens/Login');
const OrdersImport = () => import('~screens/Backoffice/');
const HomeImport = () => import('~screens/Commerce');

export const Login = dynamicImportComponent(LoginImport, BACKOFFICE_PAGES.LOGIN.path);
export const Orders = dynamicImportComponent(OrdersImport, BACKOFFICE_PAGES.ORDERS.path);
export const Home = dynamicImportComponent(HomeImport, CLIENT_PAGES.HOME.path);
