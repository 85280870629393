import immutable from 'seamless-immutable';
import { createReducer, completeReducer, completeState, onReadValue, onSetValue } from 'redux-recompose';

import { actions } from './actions';

const defaultState = completeState(
  {
    initialLoading: true,
    login: null,
    currentUser: null,
    isBackoffice: false,
    successMessage: false,
    users: [],
    user: null
  },
  ['initialLoading', 'successMessage']
);

const reducerDescription = {
  primaryActions: [actions.LOGIN, actions.GET_CURRENT_USER, actions.GET_USERS, actions.GET_USER_BY_ID],
  override: {
    [actions.INIT]: onSetValue(false),
    [actions.SET_BACKOFFICE]: onReadValue(),
    [actions.SET_SUCCESS_MESSAGE]: onReadValue(),
    [actions.LOGOUT]: onSetValue(null)
  }
};

const reducer = createReducer(immutable(defaultState), completeReducer(reducerDescription));
export default reducer;
