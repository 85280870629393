import i18next from 'i18next';

i18next.addResources('es_AR', 'ConfirmationModal', {
  details: 'DETALLES DEL PEDIDO',
  products: 'PRODUCTOS',
  confirmation: '¡PEDIDO REALIZADO!',
  confirm: 'CONFIRMAR',
  cancel: 'CANCELAR',
  close: 'CERRAR',
  noitemsSelected: 'No has seleccionado ningun producto. Por favor, agregar productos a la compra.',
  congrats: '¡Felicitaciones! Tu pedido fue realizado con éxito.',
  mail: 'En unos instantes te llegará un mail con el detalle de la orden.',
  paymentText: 'Recordá que el pago se realiza en efectivo en la entrega.',
  dateText: 'Revisá bien la fecha! Tomamos pedidos hasta el día anterior a las 21hs!'
});
