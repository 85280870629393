import api from '~config/api';

const login = values => api.post('/sign_in', values);

const getCurrentUser = () => api.get('/me');

const getUsers = () => api.get('/users');

const getUserById = id => api.get(`/users/${id}`);

export default {
  login,
  getCurrentUser,
  getUsers,
  getUserById
};
