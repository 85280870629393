import i18next from 'i18next';

i18next.addResources('es_AR', 'Field', {
  email: 'Email',
  name: 'Nombre',
  surname: 'Apellido',
  phone: 'Teléfono',
  invalid: 'El campo {{ field }} es inválido',
  required: 'El campo {{ field }} es requerido',
  matches: '{{field}} no cumple con el formato'
});
