import { createTypes, completeTypes } from 'redux-recompose';

import ZonesService from '../../services/Zones/service';

export const actions = createTypes(completeTypes(['GET_ZONES'], ['SET_LOCALITY']), '@@ZONES');

const targets = {
  ZONES: 'zones',
  LOCALITY: 'locality'
};

const actionCreators = {
  getZones: () => ({
    type: actions.GET_ZONES,
    service: ZonesService.getZones,
    target: targets.ZONES
  }),
  setLocality: locality => ({
    type: actions.SET_LOCALITY,
    target: targets.LOCALITY,
    payload: locality
  })
};

export default actionCreators;
