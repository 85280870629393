import { createTypes, completeTypes } from 'redux-recompose';

import ProductsService from '../../services/Products/service';

export const actions = createTypes(completeTypes(['GET_PRODUCTS'], []), '@@PRODUCTS');

const targets = {
  PRODUCTS: 'products'
};

const actionCreators = {
  getProducts: () => ({
    type: actions.GET_PRODUCTS,
    service: ProductsService.getProducts,
    target: targets.PRODUCTS
  })
};

export default actionCreators;
