import { toIsoWithTimezone } from '~utils/date';

export const orderCreationMapper = ({
  name,
  address,
  phone,
  email,
  deliver,
  totalPrice,
  status,
  timestamps
}) => state => {
  const { cart } = state;
  return {
    name,
    address,
    mobileNumber: phone,
    email,
    deliver: {
      ...deliver,
      date: toIsoWithTimezone(deliver.date)
    },
    items: cart.cart,
    totalPrice,
    status,
    timestamps
  };
};

export const updateOrderState = (order, newStatus, orders) => {
  const updateOrder = orders.find(o => o.orderNumber === order.orderNumber);
  return { ...updateOrder, status: newStatus };
};
