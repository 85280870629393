import { createTypes } from 'redux-recompose';

import { addItemToCart, removeItemFromCart } from '~utils/product';

export const actions = createTypes(['SUM_ITEM', 'REMOVE_ITEM', 'CLEAN_CART']);

const targets = {
  CART: 'cart'
};

const actionCreators = {
  sumItem: item => (dispatch, getState) => {
    const { cart } = getState().cart;
    dispatch({
      type: actions.SUM_ITEM,
      target: targets.CART,
      payload: addItemToCart(item, cart)
    });
  },
  removeItem: item => (dispatch, getState) => {
    const { cart } = getState().cart;
    dispatch({
      type: actions.REMOVE_ITEM,
      target: targets.CART,
      payload: removeItemFromCart(item, cart)
    });
  },
  cleanCart: () => ({
    type: actions.CLEAN_CART,
    target: targets.CART,
    payload: []
  })
};

export default actionCreators;
