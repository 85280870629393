import { createTypes, completeTypes, withPostSuccess } from 'redux-recompose';

import LocalStorageService from '~services/LocalStorageService';
import { setAuthHeader } from '~config/api';

import OrderService from '../../services/Order/service';

import { orderCreationMapper } from './mappers';

export const actions = createTypes(
  completeTypes(['CREATE_ORDER', 'GET_ORDERS', 'UPDATE_ORDER', 'DOWNLOAD_ORDERS'], []),
  '@@ORDER'
);

const targets = {
  CREATE_ORDER: 'createOrder',
  ORDERS: 'orders',
  UPDATE_ORDER: 'updateOrder',
  DOWNLOAD_ORDERS: 'downloadOrders'
};

const actionCreators = {
  createOrder: (order, handleConfirmation, reset) => ({
    type: actions.CREATE_ORDER,
    target: targets.CREATE_ORDER,
    payload: orderCreationMapper(order),
    service: OrderService.createOrder,
    injections: [
      withPostSuccess(() => {
        handleConfirmation();
        reset();
      })
    ]
  }),
  getOrders: () => ({
    type: actions.GET_ORDERS,
    target: targets.ORDERS,
    payload: setAuthHeader(LocalStorageService.getSessionToken()),
    service: OrderService.getOrders
  }),
  updateOrder: (order, status) => ({
    type: actions.UPDATE_ORDER,
    target: targets.UPDATE_ORDER,
    payload: { order, status },
    service: OrderService.updateOrder
  }),
  downloadOrders: fromDate => ({
    type: actions.DOWNLOAD_ORDERS,
    target: targets.DOWNLOAD_ORDERS,
    service: OrderService.downloadOrders(fromDate)
  })
};

export default actionCreators;
