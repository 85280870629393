export const priceItem = (quantity, price) => quantity * price;

export const priceCart = cart =>
  cart.reduce((accum, item) => accum + priceItem(item.quantity, item.price), 0);

export const addItemToCart = (item, cart) => {
  if (cart.some(cartItem => cartItem.id === item.id)) {
    return cart.map(cartItem =>
      cartItem.id === item.id ? { ...cartItem, quantity: cartItem.quantity + 1 } : cartItem
    );
  }

  const newItem = { ...item, quantity: 1 };
  return [...cart, newItem];
};

export const removeItemFromCart = (item, cart) => {
  if (cart.some(cartItem => cartItem.id === item.id && cartItem.quantity === 1)) {
    return cart.filter(cartItem => cartItem.id !== item.id);
  }

  if (cart.some(cartItem => cartItem.id === item.id)) {
    return cart.map(cartItem =>
      cartItem.id === item.id ? { ...cartItem, quantity: cartItem.quantity - 1 } : cartItem
    );
  }
};
