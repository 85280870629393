import i18next from 'i18next';

i18next.addResources('es_AR', 'PurchaseOrder', {
  title: '¡HACÉ TU PEDIDO!',
  nameAndSurname: 'Nombre y apellido',
  address: 'Dirección (Calle - Número - Piso - Depto)',
  postalCode: 'Código postal',
  zone: 'Zona',
  weekday: 'Dia en la semana',
  locality: 'Localidad',
  phone: 'Teléfono',
  email: 'Email'
});

i18next.addResources('es_AR', 'Errors', {
  required: 'Este campo es requerido',
  nameAndSurname: 'Nombre y apellido inválidos',
  postalCode: 'Código postal inválido',
  postalCodeLength: 'Debe tener 4 dígitos',
  phone: 'Teléfono inválido',
  phoneLength: 'Debe tener 10 dígitos',
  email: 'Email inválido'
});
