import immutable from 'seamless-immutable';
import { createReducer, completeReducer, completeState, onReadValue } from 'redux-recompose';

import { actions } from './actions';

const defaultState = completeState({
  zones: [],
  locality: null
});

const reducerDescription = {
  primaryActions: [actions.GET_ZONES],
  override: {
    [actions.SET_LOCALITY]: onReadValue()
  }
};

const reducer = createReducer(immutable(defaultState), completeReducer(reducerDescription));
export default reducer;
