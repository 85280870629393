import Immutable from 'seamless-immutable';
import { createReducer, completeReducer, completeState } from 'redux-recompose';

import { actions } from './actions';

const stateDescription = {
  createOrder: null,
  orders: [],
  updateOrder: null
};

const initialState = completeState(stateDescription);

const reducerDescription = {
  primaryActions: [actions.CREATE_ORDER, actions.GET_ORDERS, actions.UPDATE_ORDER, actions.DOWNLOAD_ORDERS]
};

const reducer = createReducer(new Immutable(initialState), completeReducer(reducerDescription));

export default reducer;
