import i18next from 'i18next';

i18next.addResources('es_AR', 'Backoffice', {
  orders: 'Pedidos',
  products: 'Productos',
  editOrder: 'Editar Pedidos',
  orderID: 'N° Orden',
  client: 'Cliente',
  zone: 'Zona',
  orderDate: 'Pedido',
  deliveryDate: 'Entrega',
  filterDaily: 'Filtrar pedidos del día',
  filterDailyBack: 'Volver a todos los pedidos',
  filterDailyMobile: 'Filtrar',
  filterDailyBackMobile: 'Volver',
  type: 'Tipo',
  detail: 'Detalle',
  price: 'Precio',
  status: 'Estado',
  saveState: 'Guardar cambios',
  search: 'Buscar orden',
  downloadButton: 'Exportar pedidos del día',
  downloadButtonMobile: 'Exportar',
  address: 'Dirección',
  blank: '----'
});
